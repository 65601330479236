import { Notifications } from '@mui/icons-material'
import {
  Badge,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'

// Project
import {
  getAllNotificationsByUser,
  markNotificationsAsRead,
} from '~/services/notifications'

const useStyles = makeStyles(theme => ({
  readMark: {
    width: 10,
    height: 10,
    background: theme.palette.primary.main,
    borderRadius: '50%',
  },
}))

const StyledToggleButton = withStyles(theme => ({
  root: {
    '&$selected': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {},
}))(ToggleButton)

const StyledMenuItem = withStyles({
  root: {
    '& strong': {
      fontWeight: '500',
    },
    whiteSpace: 'normal',
    width: 380,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 14,
  },
})(MenuItem)

const StyledListItem = withStyles({
  root: {
    whiteSpace: 'normal',
    width: 380,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 14,
  },
})(ListItem)

function NotificationsMenu({ user }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [hasNotificationsToRead, setHasNotificationsToRead] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showAll, setShowAll] = useState(true)
  const [markedAsRead, setMarkedAsRead] = useState([])

  const notifications = useInfiniteQuery(
    ['notifications', 'list', { showAll }],
    async ({ pageParam = 0 }) =>
      await getAllNotificationsByUser({
        page: pageParam,
        size: 25,
        showAll,
      }),
    {
      refetchInterval: 5 * 60 * 1000,
      getNextPageParam: lastPage =>
        lastPage.page + 1 < lastPage.totalPages ? lastPage.page + 1 : null,
      onSuccess: data => {
        if (anchorEl || !showAll) {
          return
        }

        const hasUnreadNotifications = data.pages[0].content.some(
          notification => notification.read === false,
        )

        if (!hasUnreadNotifications) {
          return
        }

        setHasNotificationsToRead(true)
      },
    },
  )

  const markAsRead = useMutation(
    ['notifications', 'markAsRead', markedAsRead],
    async () => await markNotificationsAsRead(markedAsRead),
  )

  const NOTIFICATION_CODES = {
    surveyStoplightNoteAdded: 'notifications.codes.surveyStoplightNoteAdded',
    indicatorSuggestedChange: 'notifications.codes.indicatorSuggestedChange',
  }

  function checkIfNotificationIsInListToBeMarked(id) {
    return markedAsRead.some(readId => readId === id)
  }

  function handleChangeFilter(_event, newValue) {
    // enforcing that the filter have a value
    if (newValue === null) {
      return
    }

    setShowAll(newValue)
  }

  function handleClickMenuItem(_event, notification) {
    const alreadyInList = checkIfNotificationIsInListToBeMarked(notification.id)
    const alreadyMarkedAsRead = notification.read === true

    if (alreadyInList || alreadyMarkedAsRead) {
      return
    }

    setMarkedAsRead([...markedAsRead, notification.id])
  }

  function translationInterpolator(notification) {
    const messagesWithBold = notification.message.reduce(
      (prev, current, index) => ({
        ...prev,
        [index]: ReactDOMServer.renderToString(<strong>{current}</strong>),
      }),
      {},
    )

    return {
      createdBy: ReactDOMServer.renderToString(
        <strong>{notification.createdBy}</strong>,
      ),
      ...messagesWithBold,
    }
  }

  function handleCloseMenu(_event) {
    setAnchorEl(null)
    setHasNotificationsToRead(false)

    if (markedAsRead.length) {
      markAsRead.mutate()
    }
  }

  return (
    <>
      <IconButton
        color="default"
        onClick={event => {
          setAnchorEl(anchorEl ? null : event.currentTarget)
        }}
        aria-controls="notifications"
        aria-haspopup="menu"
        aria-label={t('notifications.aria.iconButton')}
        size="large"
      >
        <Badge
          color="primary"
          variant="dot"
          invisible={!hasNotificationsToRead}
        >
          <Notifications />
        </Badge>
      </IconButton>

      <Menu
        id="notifications"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {!notifications.isLoading && (
          <StyledListItem style={{ justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={showAll}
              exclusive
              onChange={handleChangeFilter}
              aria-label="filters"
            >
              <StyledToggleButton
                value={true}
                aria-label={t('notifications.filters.all')}
              >
                {t('notifications.filters.all')}
              </StyledToggleButton>
              <StyledToggleButton value={false} aria-label="unread">
                {t('notifications.filters.unread')}
              </StyledToggleButton>
            </ToggleButtonGroup>
          </StyledListItem>
        )}

        {notifications.isLoading &&
          [...Array(12)].map((_item, index) => (
            <StyledListItem key={index}>
              <div style={{ width: '100%' }}>
                <Skeleton animation="wave" variant="text" width="100%" />
                <Skeleton animation="wave" variant="text" width="80%" />
              </div>
            </StyledListItem>
          ))}

        {notifications.isSuccess &&
          notifications.data.pages[0].content.length === 0 && (
            <StyledListItem>{t('notifications.messages.empty')}</StyledListItem>
          )}

        {notifications.isSuccess &&
          notifications.data.pages.map(group => [
            group.content.length > 0 &&
              group.content.map(notification => (
                <StyledMenuItem
                  key={notification.id}
                  onClick={event => {
                    handleClickMenuItem(event, notification)
                  }}
                >
                  <div
                    style={{ margin: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        NOTIFICATION_CODES[notification.code],
                        translationInterpolator(notification),
                      ),
                    }}
                  />
                  <div style={{ marginLeft: 10 }}>
                    {!checkIfNotificationIsInListToBeMarked(notification.id) &&
                      !notification.read && (
                        <div className={classes.readMark} />
                      )}
                  </div>
                </StyledMenuItem>
              )),
          ])}

        {notifications.hasNextPage && (
          <StyledListItem style={{ justifyContent: 'center' }}>
            {!notifications.isFetchingNextPage ? (
              <Button
                variant="contained"
                color="primary"
                onClick={async () => await notifications.fetchNextPage()}
              >
                {t('general.showMore')}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </StyledListItem>
        )}
      </Menu>
    </>
  )
}

NotificationsMenu.propTypes = {
  /** The current user object */
  user: PropTypes.object.isRequired,
}

export default NotificationsMenu
