import { AddPhotoAlternate, RemoveCircle } from '@mui/icons-material'
import { Box, CardMedia, IconButton, Typography, alpha } from '@mui/material'
import { type FileRejection, useDropzone } from 'react-dropzone'

import {
  SUPPORTED_IMAGE_MIME_TYPES,
  toReactDropzoneSchema,
} from '~/utils/allowedFileExtensions'
// Project
import { MB_SIZE_IN_BYTES, toBase64 } from '~/utils/files-utils'

interface DropImageZoneProps {
  url: string
  file: string
  placeholder: string
  canRemovePicture?: boolean
  onChangeFile: (newFile: string) => void
}

function DropImageZone({
  url,
  file,
  placeholder,
  onChangeFile,
  canRemovePicture,
}: DropImageZoneProps) {
  const { t } = useTranslation()
  const [fileErrorType, setFileErrorType] = useState<string | null>(null)

  async function onDropAccepted(acceptedFiles: File[]) {
    setFileErrorType(null)
    const base64File = await toBase64(acceptedFiles[0])
    if (typeof base64File === 'string') onChangeFile(base64File)
  }

  function onDropRejected(fileRejetions: FileRejection[]) {
    const { errors } = fileRejetions[0]
    const errorCode = errors[0].code
    setFileErrorType(errorCode)
  }

  const { getRootProps, getInputProps, isDragActive, isFileDialogActive } =
    useDropzone({
      multiple: false,
      maxSize: 10 * MB_SIZE_IN_BYTES,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onDropAccepted,
      onDropRejected,
      accept: toReactDropzoneSchema(SUPPORTED_IMAGE_MIME_TYPES),
    })

  const isDropzoneActive = isDragActive || isFileDialogActive

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={theme => ({
            p: 5,
            py: 5,
            borderWidth: 4,
            borderRadius: 2,
            outline: 'none',
            cursor: 'pointer',
            opacity: isDropzoneActive ? 0.5 : 1,
            transition: 'all 500ms ease-in-out',

            gap: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',

            borderStyle: 'dashed',
            borderColor: isDropzoneActive
              ? theme.palette.primary.light
              : theme.palette.grey[400],
            backgroundColor: isDropzoneActive
              ? alpha(theme.palette.primary.light, 0.25)
              : theme.palette.grey[100],
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <AddPhotoAlternate
            sx={{
              fontSize: '8vh',
              transition: 'all 500ms ease-in-out',
              color: theme =>
                isDropzoneActive
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{
              transition: 'all 500ms ease-in-out',
              color: theme =>
                isDropzoneActive
                  ? theme.palette.grey[800]
                  : theme.palette.grey[400],
            }}
          >
            {placeholder}
          </Typography>
        </Box>

        {(!!file || !!url) && (
          <>
            <CardMedia
              component="img"
              alt={placeholder}
              image={file || url}
              sx={{
                //* NOTE: Son of a b*tch, I've been trying to center
                //* this crap for a couple of hours. If you find a better way
                //* then do it, genius
                top: '50%',
                left: '50%',
                width: '90%',
                height: '90%',
                transform: 'translate(-50%,-50%)',

                borderRadius: 1,
                position: 'absolute',
                objectFit: 'contain',
                pointerEvents: 'none',

                bgcolor: theme => theme.palette.grey[100],
              }}
            />
            {canRemovePicture && (
              <IconButton
                onClick={() => {
                  onChangeFile('')
                }}
                sx={{ top: 0, right: 0, position: 'absolute' }}
              >
                <RemoveCircle fontSize="large" />
              </IconButton>
            )}
          </>
        )}
      </Box>

      {fileErrorType === 'file-too-large' && (
        <Typography color="error.main">
          {t('views.dropImageZone.fileUploadError')}
        </Typography>
      )}
      {fileErrorType === "file-invalid-type'" && (
        <Typography color="error.main">
          {t('views.dropImageZone.fileUploadErrorType')}
        </Typography>
      )}
    </>
  )
}

export default DropImageZone
