import { zodResolver } from '@hookform/resolvers/zod'
import {
  CheckCircle,
  ForwardToInbox,
  PublishedWithChanges,
} from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useMutation } from '@tanstack/react-query'
import GoogleLibPhoneNumber from 'google-libphonenumber'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import familyFaceIcon from '~/assets/family_face_large.png'
import BottomSpacer from '~/components/BottomSpacer'
import NoProdWarningBanner from '~/components/NoProdWarningBanner'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import {
  updateDraft,
  updateFamilyMemberFieldByIndex,
} from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
  sendEmailVerificationCode,
  verifyEmailVerificationCode,
} from '~/services/lifemap-survey-services'
import { callingCodes } from '~/utils/calling-codes'
import { getDraftWithUpdatedQuestionsCascading } from '~/utils/conditional-logic'
import { capitalize } from '~/utils/form-utils'
import { getDateMaskFormat } from '~/utils/format-date'
import { useLocalizedCountries } from '~/utils/hooks/useLocalizedCountries'
import { type SurveyConfigCustomLabelType } from '~/utils/types/current-survey'
import { excludeFalsyWithMessage } from '~/utils/zod'

const phoneUtil = GoogleLibPhoneNumber.PhoneNumberUtil.getInstance()

const PREFER_NOT_TO_SAY_OPTION_VALUE = -1
const MIN_DATE_OF_BIRTH = '1910-01-01'

export default function PrimaryParticipant() {
  const location = useLocation()
  const navigate = useNavigate()
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })
  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const dispatch = useAppDispatch()

  const hasEmailVerification = currentSurvey.surveyConfig.verifiedEmailRequired

  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  // NOTE: maybe we can avoid using the route state, I think `agreedTerms` is
  // static and `projectId` can be passed as query param
  const state = useMemo(
    () =>
      location.state as
        | { projectId?: number; agreedTerms?: boolean }
        | null
        | undefined,
    [location],
  )
  const countries = useLocalizedCountries(i18n.language)

  const householdMembersSizeOptions: Array<{ text: string; value: number }> =
    Array.from(Array(26).keys())
      .map(item => item + 1)
      .map(item => {
        switch (item) {
          case 1:
            return { text: t('views.family.onlyPerson'), value: 1 }
          case 26:
            return {
              text: t('views.family.preferNotToSay'),
              value: PREFER_NOT_TO_SAY_OPTION_VALUE,
            }
          default:
            return { text: item.toString(), value: item }
        }
      })

  const countryCode =
    currentSurvey.surveyConfig.surveyLocation.country === 'CA'
      ? 'US'
      : currentSurvey.surveyConfig.surveyLocation.country

  const phoneCode = callingCodes.find(e => e.code === countryCode) ?? null

  const customInputLabels = currentSurvey.customLabels?.reduce<
    Partial<Record<SurveyConfigCustomLabelType, string>>
  >((accumulator, current) => {
    const value = current.labelText
    if (!value) return accumulator
    accumulator[current.labelType] = value
    return accumulator
  }, {})

  const otherGenderOption = currentSurvey.surveyConfig.gender.find(
    g => g.otherOption,
  )

  const otherDocumentTypeOption = currentSurvey.surveyConfig.documentType.find(
    d => d.otherOption,
  )

  const excludeFalsy = useMemo(
    () => excludeFalsyWithMessage(t('validation.fieldIsRequired')),
    [t],
  )

  const validationSchema = z
    .object({
      firstName: z.string().trim().min(1, t('validation.fieldIsRequired')),
      lastName: z.string().trim().min(1, t('validation.fieldIsRequired')),
      gender: z.string().min(1, t('validation.fieldIsRequired')),
      customGender: z
        .string()
        .trim()
        .min(1, t('validation.fieldIsRequired'))
        .optional(),
      birthDate: z
        .custom<moment.Moment>(value => moment.isMoment(value))
        .nullable()
        .transform(excludeFalsy)
        .refine(
          value =>
            moment(value).isSameOrAfter(MIN_DATE_OF_BIRTH) &&
            moment(value).isSameOrBefore(moment()),
          t('validation.validDate'),
        ),
      documentType: z.string().trim().min(1, t('validation.fieldIsRequired')),
      customDocumentType: z
        .string()
        .trim()
        .min(1, t('validation.fieldIsRequired'))
        .optional(),
      documentNumber: z.string().trim().min(1, t('validation.fieldIsRequired')),
      birthCountry: z
        .object({ code: z.string(), label: z.string() })
        .nullable()
        .transform(excludeFalsy),
      countFamilyMembers: z
        .union([z.number(), z.literal('')])
        .transform(excludeFalsy),
      email: z
        .string()
        .trim()
        .email(t('validation.validEmailAddress'))
        .optional()
        .or(z.literal('')),
      emailVerificationCode: z.string().trim().optional(),
      isEmailVerified: z.boolean().optional(),
      phone: z
        .object({
          code: z
            .object({
              country: z.string(),
              value: z.string(),
              code: z.string(),
            })
            .nullable()
            .optional(),
          number: z.string().optional().or(z.literal('')),
        })
        .refine(
          ({ code, number }) => {
            // NOTE: code and number are optional so we're just skipping the
            // validation in case one of them is missing
            if (!code || !number) return true

            try {
              const international = `+${code.value} ${number}`
              const phone = phoneUtil.parse(international, code.code)
              return phoneUtil.isValidNumberForRegion(phone, code.code)
            } catch {
              return false
            }
          },
          { path: ['number'], message: t('validation.validPhoneNumber') },
        ),
    })
    .superRefine(
      (
        {
          gender,
          customGender,
          documentType,
          customDocumentType,
          email,
          isEmailVerified,
          emailVerificationCode,
        },
        context,
      ) => {
        if (gender === otherGenderOption?.value && !customGender) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.fieldIsRequired'),
            path: ['customGender'],
          })
          return
        }

        if (
          documentType === otherDocumentTypeOption?.value &&
          !customDocumentType
        ) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.fieldIsRequired'),
            path: ['customDocumentType'],
          })
          return
        }

        if (currentSurvey.surveyConfig.verifiedEmailRequired && !email) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.fieldIsRequired'),
            path: ['email'],
          })
          return
        }

        if (
          hasEmailVerification &&
          emailVerificationCode &&
          emailVerificationCode.length < 6
        ) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.fieldIsRequired'),
            path: ['emailVerificationCode'],
          })
          return
        }

        if (hasEmailVerification && !isEmailVerified) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('validation.verifyEmail'),
            path: ['emailVerificationCode'],
          })
          return
        }

        return true
      },
    )

  type FormValues = z.input<typeof validationSchema>

  const form = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
    // @ts-expect-error `values` requires the whole object but we have
    // uncontrolled inputs too and the async version of `defaultValues` was not
    // working for me
    values: {
      gender: '',
      birthDate: null,
      documentType: '',
      birthCountry:
        countries.find(
          country =>
            currentSurvey.surveyConfig.surveyLocation.country === country.code,
        ) ?? null,
      countFamilyMembers: currentDraft.familyData.countFamilyMembers ?? '',
      phone: { code: phoneCode },
    },
  })

  const sendEmail = useMutation({
    mutationFn: async (payload: { email: string }) =>
      await sendEmailVerificationCode(payload.email),

    onSuccess(data, _variables, _context) {
      const isSuccess = data.sendEmailVerificationCode.successful
      if (isSuccess) {
        enqueueSnackbar(t('validation.successfullySentEmail'), {
          variant: 'success',
        })
      }
    },
  })

  const verifyEmail = useMutation({
    mutationFn: async (payload: { email: string; code: string }) => {
      form.setValue('isEmailVerified', false)

      return await verifyEmailVerificationCode(payload.email, payload.code)
    },
    onSuccess: data => {
      const status = data.verifyEmailCode.content
      const isSuccess = status === 'SUCCESSFUL'

      if (isSuccess) {
        enqueueSnackbar('Email verified', { variant: 'success' })
        form.setValue('isEmailVerified', true)
        return
      }

      const errorMessage = t('validation.invalidEmailCode')

      enqueueSnackbar(errorMessage, { variant: 'error' })
      form.setError('emailVerificationCode', { message: errorMessage })
      form.setValue('isEmailVerified', false)
    },
    onError: () => {
      enqueueSnackbar(t('general.error'), { variant: 'error' })
      form.setValue('isEmailVerified', false)
    },
  })

  async function handleSubmit(_formValues: FormValues) {
    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveSnapshotDraft(normalizedDraft)

    const membersCount = currentDraft.familyData.familyMembersList.length

    const areMemberQuestionsDisabled =
      currentSurvey.surveyConfig.disableMemberQuestions
    const selectedOneFamilyMember =
      currentDraft.familyData.countFamilyMembers === 1
    const selectedPreferNotToSay =
      currentDraft.familyData.countFamilyMembers ===
      PREFER_NOT_TO_SAY_OPTION_VALUE

    if (areMemberQuestionsDisabled) {
      navigate('/lifemap/location')
      return
    }

    if (
      (selectedOneFamilyMember || selectedPreferNotToSay) &&
      membersCount === 1
    ) {
      navigate('/lifemap/location')
      return
    }

    navigate('/lifemap/family-members')
  }

  const updateFamilyMembersCount = useCallback(
    (value: number) => {
      // Covering the case where user does not want to specify
      if (value === 1 || value === PREFER_NOT_TO_SAY_OPTION_VALUE) {
        dispatch(
          updateDraft({
            ...currentDraft,
            familyData: {
              ...currentDraft.familyData,
              countFamilyMembers: value,
            },
          }),
        )
      } else if (currentDraft.familyData.familyMembersList.length < value) {
        //* Adding new members
        const names = [...currentDraft.familyData.familyMembersList]
        const areMemberQuestionsDisabled =
          currentSurvey.surveyConfig.disableMemberQuestions

        for (
          let i = currentDraft.familyData.familyMembersList.length;
          i <= value - 1;
          i += 1
        ) {
          names.push({
            firstName: areMemberQuestionsDisabled ? `Member ${i + 1}` : '',
            gender: '',
            // @ts-expect-error
            birthDate: null,
            firstParticipant: false,
            socioEconomicAnswers: [],
          })
        }
        dispatch(
          updateDraft({
            ...currentDraft,
            familyData: {
              ...currentDraft.familyData,
              countFamilyMembers: value,
              familyMembersList: names,
            },
          }),
        )
      } else if (currentDraft.familyData.familyMembersList.length > value) {
        //* Removing members
        dispatch(
          updateDraft({
            ...currentDraft,
            familyData: {
              ...currentDraft.familyData,
              countFamilyMembers: value,
            },
          }),
        )
      } else {
        // Same number of members and counts
        dispatch(
          updateDraft({
            ...currentDraft,
            familyData: {
              ...currentDraft.familyData,
              countFamilyMembers: value,
            },
          }),
        )
      }
    },
    [dispatch, currentDraft],
  )

  useEffect(() => {
    const agreedTerms = state?.agreedTerms ?? !!currentDraft.agreedTerms

    if (!agreedTerms) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate('/lifemap/terms')
      return
    }

    const hasEconomicTime = currentDraft.economicTime
    const hasStoplightTime = currentDraft.stoplightTime
    const hasLifemapTime = currentDraft.lifemapTime

    //* If the user is taking a followup survey, he won't have any times nor agreedTerms
    //* so we need to update the survey with them
    if (
      !hasEconomicTime ||
      !hasStoplightTime ||
      !hasLifemapTime ||
      !currentDraft.agreedTerms
    ) {
      dispatch(
        updateDraft({
          ...currentDraft,
          lifemapTime: [],
          stoplightTime: [],
          agreedTerms: true,
          economicTime: [{ event: 'start', time: Date.now() }],
        }),
      )
    }
  }, [state, enqueueSnackbar, history, currentSurvey, dispatch, t, navigate])

  // NOTE: Form -> Redux state synchronization
  useEffect(() => {
    const PRIMARY_PARTICIPANT_INDEX = 0

    const subscription = form.watch((value, { name }) => {
      if (!name) return

      switch (name) {
        case 'firstName':
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: 'firstName',
              value: capitalize(value.firstName),
            }),
          )
          break

        case 'lastName':
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: 'lastName',
              value: capitalize(value.lastName),
            }),
          )
          break

        case 'birthDate': {
          const birthDate = value.birthDate
          if (!birthDate) return
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: 'birthDate',
              // @ts-expect-error types are incorrect
              value: birthDate.unix(),
            }),
          )
          break
        }

        case 'countFamilyMembers': {
          const count = value.countFamilyMembers
          if (!count) return
          updateFamilyMembersCount(count)
          break
        }

        case 'birthCountry':
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: 'birthCountry',
              value: value.birthCountry?.code,
            }),
          )
          break

        case 'phone.code':
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: 'phoneCode',
              value: value.phone?.code?.value,
            }),
          )
          break

        case 'phone.number':
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: 'phoneNumber',
              value: value.phone?.number,
            }),
          )
          break

        case 'emailVerificationCode':
          if (hasEmailVerification) {
            dispatch(
              updateFamilyMemberFieldByIndex({
                index: PRIMARY_PARTICIPANT_INDEX,
                field: 'emailVerificationCode',
                value: value.emailVerificationCode,
              }),
            )
          }
          break

        case 'isEmailVerified':
          if (hasEmailVerification) {
            dispatch(
              updateFamilyMemberFieldByIndex({
                index: PRIMARY_PARTICIPANT_INDEX,
                field: 'isEmailVerified',
                value: value.isEmailVerified,
              }),
            )
          }
          break

        default: {
          dispatch(
            updateFamilyMemberFieldByIndex({
              index: PRIMARY_PARTICIPANT_INDEX,
              field: name,
              value: value[name],
            }),
          )

          const {
            conditionalQuestions = [],
            elementsWithConditionsOnThem: { memberKeysWithConditionsOnThem },
          } = currentSurvey

          if (memberKeysWithConditionsOnThem.includes(name) && currentDraft) {
            const newDraft = getDraftWithUpdatedQuestionsCascading(
              currentDraft,
              conditionalQuestions,
            )

            dispatch(updateDraft(newDraft))
          }

          break
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [form.watch, dispatch, updateFamilyMembersCount])

  // NOTE: Redux -> Form state synchronization
  useEffect(() => {
    const primaryParticipant = currentDraft.familyData.familyMembersList[0]

    form.reset({
      ...primaryParticipant,
      birthDate: moment.unix(primaryParticipant.birthDate),
      birthCountry: countries.find(
        country => country.code === primaryParticipant.birthCountry,
      ),
      countFamilyMembers: currentDraft.familyData.countFamilyMembers,
      phone: {
        number: primaryParticipant.phoneNumber ?? undefined,
        code: callingCodes.find(
          callingCode => callingCode.value === primaryParticipant.phoneCode,
        ),
      },
      customGender: primaryParticipant.customGender ?? undefined,
      customDocumentType: primaryParticipant.customDocumentType ?? undefined,
      email: primaryParticipant.email ?? undefined,
    })
  }, [form.reset, currentDraft, countries])

  // NOTE: This can be undefined in runtime
  // Fix for https://povertystoplight.atlassian.net/browse/PSP-2271
  const watchGender = form.watch('gender') ?? ''

  // NOTE: This can be undefined in runtime
  // Fix for https://povertystoplight.atlassian.net/browse/PSP-2271
  const watchDocumentType = form.watch('documentType') ?? ''

  const watchEmail = form.watch('email')

  return (
    <div>
      <TitleBar title={t('views.primaryParticipant')} progressBar />

      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 4,
            marginBottom: 2,
          }}
        >
          <img height={60} width={60} src={familyFaceIcon} alt="" />
        </Box>

        <Container maxWidth="sm">
          <NoProdWarningBanner />

          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
              <TextField
                {...form.register('firstName')}
                label={
                  customInputLabels?.firstName ?? t('views.family.firstName')
                }
                error={Boolean(form.formState.errors.firstName)}
                helperText={form.formState.errors.firstName?.message}
                variant={'standard'}
                required
                fullWidth
                autoFocus
              />

              <TextField
                {...form.register('lastName')}
                label={
                  customInputLabels?.lastName ?? t('views.family.lastName')
                }
                error={Boolean(form.formState.errors.lastName)}
                helperText={form.formState.errors.lastName?.message}
                variant={'standard'}
                required
                fullWidth
              />

              <Controller
                name="gender"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="standard"
                    required
                    label={
                      customInputLabels?.gender ?? t('views.family.gender')
                    }
                    error={Boolean(form.formState.errors.gender)}
                    helperText={form.formState.errors.gender?.message}
                  >
                    {currentSurvey.surveyConfig.gender.map(gender => (
                      <MenuItem key={gender.value} value={gender.value}>
                        {gender.text}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {watchGender === otherGenderOption?.value && (
                <TextField
                  {...form.register('customGender')}
                  label={`${t('views.family.specify')} ${
                    customInputLabels?.gender?.toLowerCase() ??
                    t('views.family.gender').toLowerCase()
                  }`}
                  error={Boolean(form.formState.errors.customGender)}
                  helperText={form.formState.errors.customGender?.message}
                  variant={'standard'}
                  required
                  fullWidth
                />
              )}

              <Controller
                name="birthDate"
                control={form.control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={
                      customInputLabels?.birthDate ??
                      t('views.family.dateOfBirth')
                    }
                    minDate={moment(MIN_DATE_OF_BIRTH)}
                    maxDate={moment()}
                    format={getDateMaskFormat()}
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        error: !!form.formState.errors.birthDate?.message,
                        helperText: form.formState.errors.birthDate?.message,
                        onBlur: field.onBlur,
                        required: true,
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="documentType"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    select
                    required
                    label={
                      customInputLabels?.documentType ??
                      t('views.family.documentType')
                    }
                    variant="standard"
                    error={Boolean(form.formState.errors.documentType)}
                    helperText={form.formState.errors.documentType?.message}
                    {...field}
                  >
                    {currentSurvey.surveyConfig.documentType.map(
                      documentType => (
                        <MenuItem
                          key={documentType.value}
                          value={documentType.value}
                        >
                          {documentType.text}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                )}
              />

              {watchDocumentType === otherDocumentTypeOption?.value && (
                <TextField
                  {...form.register('customDocumentType')}
                  label={`${t('views.family.specify')} ${
                    customInputLabels?.documentType?.toLowerCase() ??
                    t('views.family.documentType').toLowerCase()
                  }`}
                  error={Boolean(form.formState.errors.customDocumentType)}
                  helperText={form.formState.errors.customDocumentType?.message}
                  variant={'standard'}
                  required
                  fullWidth
                />
              )}

              <TextField
                {...form.register('documentNumber')}
                label={
                  customInputLabels?.documentNumber ??
                  t('views.family.documentNumber')
                }
                error={Boolean(form.formState.errors.documentNumber)}
                helperText={form.formState.errors.documentNumber?.message}
                variant={'standard'}
                fullWidth
                required
              />

              <Controller
                name="birthCountry"
                control={form.control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={countries}
                    autoHighlight
                    getOptionLabel={option => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.code === value.code
                    }
                    onChange={(_event, newValue) => {
                      field.onChange(newValue)
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, borderRadius: 0.4 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant={'standard'}
                        label={
                          customInputLabels?.birthCountry ??
                          t('views.family.countryOfBirth')
                        }
                        error={Boolean(form.formState.errors.birthCountry)}
                        helperText={form.formState.errors.birthCountry?.message}
                        required
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="countFamilyMembers"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    label={
                      customInputLabels?.countFamilyMembers ??
                      t('views.family.peopleLivingInThisHousehold')
                    }
                    variant={'standard'}
                    select
                    required
                    helperText={
                      form.formState.errors.countFamilyMembers?.message
                    }
                    error={Boolean(form.formState.errors.countFamilyMembers)}
                    {...field}
                  >
                    {householdMembersSizeOptions.map(size => (
                      <MenuItem key={size.value} value={size.value}>
                        {size.text}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <TextField
                {...form.register('email', {
                  onChange(event) {
                    form.setValue('emailVerificationCode', '')
                    form.setValue('isEmailVerified', false)
                    return event
                  },
                })}
                label={customInputLabels?.email ?? t('views.family.email')}
                error={Boolean(form.formState.errors.email)}
                helperText={form.formState.errors.email?.message}
                variant={'standard'}
                fullWidth
              />

              {hasEmailVerification &&
                watchEmail &&
                !form.formState.errors.email && (
                  <Button
                    variant="outlined"
                    endIcon={<ForwardToInbox />}
                    onClick={() => {
                      sendEmail.mutate({ email: watchEmail })
                    }}
                    disabled={sendEmail.isLoading}
                  >
                    {t('views.family.sendVerificationCode')}
                  </Button>
                )}

              {currentSurvey.surveyConfig.verifiedEmailRequired && (
                <Controller
                  name="emailVerificationCode"
                  control={form.control}
                  render={({ field }) => {
                    const isEmailVerified =
                      form.getValues('isEmailVerified') ?? false

                    const showSuccess = isEmailVerified
                    const showLoading = verifyEmail.isLoading
                    const showVerify = !isEmailVerified && !showLoading

                    const disableInput =
                      isEmailVerified || !watchEmail || verifyEmail.isLoading

                    const disableButton =
                      !watchEmail ||
                      !field.value ||
                      field.value.length < 6 ||
                      verifyEmail.isLoading ||
                      isEmailVerified

                    return (
                      <PatternFormat
                        mask="_"
                        required
                        fullWidth
                        format="# # # # # #"
                        variant="standard"
                        allowEmptyFormatting
                        customInput={TextField}
                        disabled={disableInput}
                        label={t('views.family.verificationCode')}
                        value={field.value}
                        onValueChange={({ value }) => {
                          field.onChange(value)
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={disableButton}
                                onClick={() => {
                                  if (!watchEmail || !field.value) return
                                  verifyEmail.mutate({
                                    email: watchEmail,
                                    code: field.value,
                                  })
                                }}
                              >
                                {showSuccess && <CheckCircle color="primary" />}
                                {showVerify && (
                                  <Tooltip
                                    arrow
                                    open={showVerify && !disableButton}
                                    title={t(
                                      'views.family.verifyCodeHelperText',
                                    )}
                                  >
                                    <PublishedWithChanges />
                                  </Tooltip>
                                )}
                                {showLoading && <CircularProgress size={25} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(
                          form.formState.errors.emailVerificationCode,
                        )}
                        helperText={
                          form.formState.errors.emailVerificationCode?.message
                        }
                        sx={{
                          input: {
                            fontWeight: 900,
                            textAlign: 'center',
                            fontSize: 24,
                            color: theme => theme.palette.primary.main,
                          },
                        }}
                      />
                    )
                  }}
                />
              )}

              <Controller
                name="phone.code"
                control={form.control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={callingCodes}
                    autoHighlight
                    onBlur={field.onBlur}
                    getOptionLabel={option =>
                      `${option.country} (+${option.value})`
                    }
                    onChange={(_event, newValue) => {
                      field.onChange(newValue)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, borderRadius: 0.4 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.country} (+{option.value})
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant={'standard'}
                        label={
                          customInputLabels?.phoneCode ??
                          t('views.family.phoneCode')
                        }
                        error={Boolean(form.formState.errors.phone?.code)}
                        helperText={form.formState.errors.phone?.code?.message}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
              />

              <TextField
                {...form.register('phone.number')}
                label={
                  customInputLabels?.phoneNumber ?? t('views.family.phone')
                }
                error={Boolean(form.formState.errors.phone?.number)}
                helperText={form.formState.errors.phone?.number?.message}
                variant={'standard'}
                fullWidth
              />

              <Box sx={{ textAlign: 'center' }}>
                <Button type="submit" variant="contained">
                  {t('general.continue')}
                </Button>
              </Box>
            </Box>
          </form>

          <BottomSpacer />
        </Container>
      </>
    </div>
  )
}
