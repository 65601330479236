import { type ServerResponse, axios } from '~/axios'
import { type PlatformLanguage } from '~/utils/types/i18n'

export const addOrUpdateStakeholder = async values => {
  if (values.id) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query: `mutation updateStakeholder($stakeholder: StakeholderModelInput) { updateStakeholder (stakeholder:$stakeholder) { successful} }`,
        variables: {
          stakeholder: {
            id: values.id,
            name: values.name,
            description: values.description,
            active: values.active,
            country: values.country.value,
            logoUrl: values.logoUrl,
            language: values.language,
            stakeholderType: values.stakeholderType,
            areaOfExpertise: values.areaOfExpertise,
          },
        },
      }),
    })
  }
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation createStakeholder($stakeholder: StakeholderModelInput) { createStakeholder (stakeholder:$stakeholder) { successful} }`,
      variables: {
        stakeholder: {
          name: values.name,
          description: values.description,
          active: values.active,
          country: values.country.value,
          logoUrl: values.logoUrl,
          language: values.language,
          stakeholderType: values.stakeholderType,
          areaOfExpertise: values.areaOfExpertise,
        },
      },
    }),
  })
}

export const getStakeholderPaginated = async page =>
  await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query listStakeholder($page: Int) { listStakeholder(page:$page ){content {id name description language active country stakeholderType logoUrl areaOfExpertise} totalPages totalElements }}',
      variables: {
        page,
      },
    }),
  })

export const stakeholderListByUser = async () => {
  interface ListStakeholdersByUserResponse {
    id: number
    name: string
    description: string
    language: PlatformLanguage
    active: boolean
    country: string
    stakeholderType: string | null
    logoUrl: string | null
    areaOfExpertise: string | null
  }

  return await axios<
    ServerResponse<{ listStakeholdersByUser: ListStakeholdersByUserResponse[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query { listStakeholdersByUser {id name description language active country stakeholderType logoUrl areaOfExpertise } }',
    }),
  })
}

export const deleteStakeholder = async id =>
  await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteStakeholder($id: Long) { deleteStakeholder (id:$id) { successful} }',
      variables: {
        id,
      },
    }),
  })
