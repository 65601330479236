import docPreview from '~/assets/doc.png'
import pdfPreview from '~/assets/pdf.png'
import pptPreview from '~/assets/ppt.png'
import { type LoadedFile } from '~/screens/solutions/FileUploader'

export const MB_SIZE_IN_BYTES = 1_048_576

export async function toBase64(file: Blob) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = error => {
      reject(error)
    }
  })
}

// TODO: This function is used in many places, try to check how many types can it have
type Resource = LoadedFile | any

export function getPreviewForFile(resource: Resource) {
  const pptMimeTypes = [
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ]

  const isImage =
    resource.type.includes('image') || resource.category?.includes('picture')

  if (isImage) return resource.url || resource.preview
  if (resource.type === 'application/pdf') return pdfPreview
  if (pptMimeTypes.includes(resource.type)) return pptPreview

  return docPreview
}

/**
 * Use this function to get a timestamp and concatenate it with a filename
 * @returns {string} timestamp in format `YYYYMMDDHHmmss`
 */
export function getFileTimestamp() {
  const date = new Date()
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')
  const hours = `${date.getHours()}`.padStart(2, '0')
  const minutes = `${date.getMinutes()}`.padStart(2, '0')
  const seconds = `${date.getSeconds()}`.padStart(2, '0')

  const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}`

  return timestamp
}

export function downloadFile(title: string, data, type) {
  const blob = new Blob([data], { type })
  const url = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', title)
  tempLink.click()
}

/**
 * Useful to avoid `CORS` problems while fetching images.
 * @param url The image's URL
 * @returns An image in base64 format Ex: `data:image/jpeg;base64,...`
 */

export const getImageAsBase64 = async (url: string) => {
  const image = document.createElement('img')
  image.crossOrigin = 'anonymous'

  // This query parameter is useful in some cases to also avoid CORS.
  image.src = `${url}?avoidCors=123`

  let mimeType = ''
  if (url.endsWith('png')) mimeType = 'image/png'
  if (url.endsWith('jpg') || url.endsWith('jpeg')) mimeType = 'image/jpeg'

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  await new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(null)
    }
  })

  if (!ctx) return
  canvas.width = image.width
  canvas.height = image.height
  ctx.drawImage(image, 0, 0, image.width, image.height)

  const base64data = canvas.toDataURL(mimeType)

  return base64data
}
