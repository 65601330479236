import { Close as CloseIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Form, Formik } from 'formik'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import propTypes from 'prop-types'
import * as Yup from 'yup'

import { getOrganizationAreaTypes, getOrganizationTypes } from '../../api'
import AutocompleteWithFormik from '../../components/AutocompleteWithFormik'
import BooleanWithFormik from '../../components/BooleanWithFormik'
import DropImageZone from '../../components/DropImageZone'
import ExitModal from '../../components/ExitModal'
import InputWithFormik from '../../components/InputWithFormik'
import CountrySelector from '../../components/selectors/CountrySelector'
import { addOrUpdateStakeholder } from '../../services/stakeholder-services'
import { useLocalizedCountries } from '../../utils/hooks/useLocalizedCountries'
import {
  getLanguageByCode,
  languageOptions,
  normalizeLanguages,
} from '../../utils/lang-utils'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 50px',
    minHeight: '35vh',
    maxHeight: '85vh',
    maxWidth: 500,
    overflowY: 'auto',
    position: 'relative',
    outline: 'none',
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginBottom: 15,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 30,
  },
}))

const StakeholderFormModal = ({
  user,
  open,
  stakeholder,
  afterSubmit,
  toggleModal,
}) => {
  const classes = useStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [openExitModal, setOpenExitModal] = useState(false)
  const [file, setFile] = useState('')
  const isCreate = !stakeholder.id

  const lang = getLanguageByCode(normalizeLanguages(language))
  const countryOptions = useLocalizedCountries(lang)

  const [options, setOptions] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      stakeholderTypes: [],
      stakeholderAreasTypes: [],
    },
  )

  const fieldIsRequired = 'validation.fieldIsRequired'

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(fieldIsRequired)
      .max(50, t('views.stakeholder.form.nameLengthExceeded')),
    description: Yup.string()
      .trim()
      .max(140, t('views.stakeholder.form.descriptionLengthExceeded')),
    language: Yup.string().required(fieldIsRequired),
    country: Yup.string().required(fieldIsRequired),
  })

  useEffect(() => {
    void getOrganizationTypes().then(response => {
      const stakeholderTypes = get(
        response,
        'data.data.organizantionTypes',
      ).map(type => ({
        value: type.code,
        label: type.description,
      }))
      setOptions({ stakeholderTypes })
    })
    void getOrganizationAreaTypes().then(response => {
      const stakeholderAreasTypes = get(
        response,
        'data.data.organizationAreasTypes',
      ).map(type => ({
        value: type.code,
        label: type.description,
      }))
      setOptions({ stakeholderAreasTypes })
    })
  }, [language, user])

  const onClose = submitted => {
    setFile('')
    if (submitted) {
      afterSubmit()
    }

    toggleModal()
  }

  const onSubmit = values => {
    const sanitazedValues = values
    if (!sanitazedValues.stakeholderType) {
      delete sanitazedValues.stakeholderType
    }
    if (!sanitazedValues.areaOfExpertise) {
      delete sanitazedValues.areaOfExpertise
    }

    addOrUpdateStakeholder({ ...sanitazedValues, logoUrl: file })
      .then(() => {
        onClose(true)
        enqueueSnackbar(t('views.stakeholder.form.save.success'), {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(t('views.stakeholder.form.save.failed'), {
          variant: 'error',
        })
        onClose(true)
      })
  }

  const formattedCountry = item => {
    return {
      label:
        countryOptions.find(country => country.code === item) &&
        countryOptions.find(country => country.code === item).label,
      value: item,
    }
  }

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      className={classes.modal}
      open={open}
      onClose={() => {
        !isCreate ? onClose(false) : setOpenExitModal(true)
      }}
    >
      <Box className={classes.innerContainer}>
        <ExitModal
          open={openExitModal}
          onDissmiss={() => {
            setOpenExitModal(false)
          }}
          onClose={() => {
            setOpenExitModal(false)
            onClose(false)
          }}
        />
        <Typography variant="h5" align="center" style={{ marginBottom: 10 }}>
          {isCreate
            ? t('views.stakeholder.form.addTitle')
            : t('views.stakeholder.form.editTitle')}
        </Typography>
        <IconButton
          className={classes.closeIcon}
          key="dismiss"
          onClick={() => {
            onClose(false)
          }}
          size="large"
        >
          <CloseIcon style={{ color: 'green' }} />
        </IconButton>
        <Formik
          initialValues={{
            id: (!!stakeholder.id && stakeholder.id) || null,
            name: (!!stakeholder.name && stakeholder.name) || '',
            description:
              (!!stakeholder.description && stakeholder.description) || '',
            active: isCreate ? true : stakeholder.active,
            language: (!!stakeholder.language && stakeholder.language) || '',
            country: stakeholder.country
              ? formattedCountry(stakeholder.country)
              : '',
            stakeholderType:
              (!!stakeholder.stakeholderType && stakeholder.stakeholderType) ||
              '',
            areaOfExpertise:
              (!!stakeholder.areaOfExpertise && stakeholder.areaOfExpertise) ||
              '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values, touched, setTouched }) => (
            <Form noValidate>
              <InputWithFormik
                label={t('views.stakeholder.form.name')}
                name="name"
                required
              />
              <InputWithFormik
                label={t('views.stakeholder.form.description')}
                name="description"
                required
              />
              <AutocompleteWithFormik
                label={t('views.stakeholder.form.language')}
                name="language"
                rawOptions={languageOptions}
                labelKey="label"
                valueKey="value"
                maxSelectMenuHeight={190}
                isClearable={false}
                required
              />
              <CountrySelector
                withTitle
                withAutoCompleteStyle
                countryData={values.country}
                onChangeCountry={async country =>
                  await setFieldValue('country', country)
                }
                onBlur={async () =>
                  await setTouched(
                    Object.assign(touched, {
                      country: true,
                    }),
                  )
                }
                parentLang={getLanguageByCode(
                  normalizeLanguages(values.language),
                )}
                error={touched.country && !values.country}
                required={true}
              />
              <DropImageZone
                file={file}
                onChangeFile={e => {
                  setFile(e)
                }}
                placeholder={
                  !isCreate && !!stakeholder.logoUrl
                    ? t('views.stakeholder.form.changeLogo')
                    : t('views.stakeholder.form.logoUpload')
                }
                url={stakeholder.logoUrl}
              />
              <AutocompleteWithFormik
                label={t('views.stakeholder.form.stakeholderType')}
                name="stakeholderType"
                rawOptions={options.stakeholderTypes}
                labelKey="label"
                valueKey="value"
                isClearable={false}
              />

              <AutocompleteWithFormik
                label={t('views.stakeholder.form.areaOfExpertise')}
                name="areaOfExpertise"
                rawOptions={options.stakeholderAreasTypes}
                labelKey="label"
                valueKey="value"
                isClearable={false}
              />
              {!isCreate && (
                <BooleanWithFormik
                  name="active"
                  label={t('views.stakeholder.form.active')}
                  onChange={async () =>
                    await setFieldValue('active', !values.active)
                  }
                />
              )}
              {isSubmitting ? (
                <CircularProgress className={classes.loadingContainer} />
              ) : null}
              <Box className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {t('general.save')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  )
}

StakeholderFormModal.propTypes = {
  user: propTypes.object.isRequired,
  open: propTypes.bool.isRequired,
  stakeholder: propTypes.object.isRequired,
  afterSubmit: propTypes.func.isRequired,
  toggleModal: propTypes.func.isRequired,
}

export default StakeholderFormModal
